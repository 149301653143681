import React from "react";
import Layout from "../voyage-ui/building-blocks/Layout/Layout";
import css from "./general.module.css";
import { Link, useLocation } from "react-router-dom";

export default function NavBarLink({ label, iconName, to }) {
  const { pathname } = useLocation();

  const backgroundImageMap = {
    homewhite:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/8b6f15a7-ccca-4055-e3bb-ae38622d4200/public",
    homeyellow:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/f6553640-766f-4499-ac8e-8312d22dd500/public",
    updateswhite:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/74fe2405-324e-426e-18c3-8075ac615b00/public",
    updatesyellow:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/f68bd2c0-145c-46b5-90f0-5a2711593a00/public",
    signupwhite:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/951b9760-8b1c-419d-48c9-00311d818400/public",
    signupyellow:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/9120d7ae-81ba-4a43-5496-7575680a0800/public",
  };
  return (
    <Link
      to={to}
      className={`${css["nav-bar-link"]} ${
        css[pathname === to ? "active" : ""]
      } ${css[to === "link" ? "disabled" : ""]}`}
    >
      <Layout
        horizontal
        centerX
        gap="var(--c1)"
        className={css["nav-bar-link-flex"]}
      >
        <div
          className={css["nav-link-icon"]}
          style={{
            backgroundImage: `url(${
              backgroundImageMap[
                iconName + (pathname === to ? "yellow" : "white")
              ]
            })`,
          }}
        />
        <p>{label}</p>
      </Layout>
    </Link>
  );
}
