import { Link } from "react-router-dom";
import Layout from "../voyage-ui/building-blocks/Layout/Layout";
import '../Pages/Home/ai-dungeon-landing.module.css'
import css from './general.module.css'

export default function UpdateCard({ image, to }) {
  return (
    <Link to={to} className={css['update-card-container']}>
      <Layout vertical gap="var(--c2)" >
        <div className={css['update-card-image']} style={{
          backgroundImage: `url(${image})`
        }} />
        <Layout horizontal centerX gap="var(--c1)">
          <div className={css['update-icon']} />
          <p>Read update notes</p>
        </Layout>
      </Layout>
    </Link>
  )
}