import React from "react";

const Grid = ({
  desktopColumns,
  tabletColumns,
  mobileColumns,
  gap,
  children,
  style,
  flex,
  desktopSpan,
  tabletSpan,
  mobileSpan,
  className
}) => {
  return (
    <div
      className={`voyage-grid ${
        desktopColumns && "desktop-col-" + desktopColumns
      } ${tabletColumns && "tablet-col-" + tabletColumns} ${
        mobileColumns && "mobile-col-" + mobileColumns
      }
      ${desktopSpan && 'desktop-span-' + desktopSpan} ${tabletSpan && 'tablet-span-' + tabletSpan} ${mobileSpan && 'mobile-span-' + mobileSpan} ${className}`}
      style={{ gap: gap ? gap : "var(--small)", flex: flex ? flex : '', ...style }}
    >
      {children}
    </div>
  );
};

export default Grid;
