import React, { Suspense, useState } from "react";
import OnImagesLoaded from "react-on-images-loaded";

const Pathfinder = React.lazy(() => import("./Pathfinder"));

const Loading = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "var(--small)",
      }}
    >
      <div className="aid-spinner" />
      <h4>Loading...</h4>
    </div>
  );
};

const HomeLoader = () => {
  const [release, setRelease] = useState("");
  const [imagesLoaded, setImagesLoaded] = useState(false);
  return (
    <>
      <div
        style={{
          opacity: 0,
          pointerEvents: "none",
          position: "absolute",
          top: 0,
        }}
      >
        <OnImagesLoaded onLoaded={() => setImagesLoaded(true)}>
          <img
            src="https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/ebd83874-a283-4520-cf64-f811a1dbd800/public"
            alt=""
            height="0"
          />
        </OnImagesLoaded>
      </div>
      {imagesLoaded ? (
        <Suspense fallback={<Loading />}>
          <Pathfinder />
        </Suspense>
      ) : (
        <>
          <input
            type="text"
            value={release}
            onChange={(e) => setRelease(e.target.value)}
            style={{
              height: window.innerHeight,
              width: window.innerWidth,
              cursor: "default",
              position: "absolute",
              opacity: 0,
            }}
          />
          <Loading />
        </>
      )}
    </>
  );
};

export default HomeLoader;
