import React from 'react'

const Layout = ({
  children,
  vertical,
  horizontal,
  gap,
  centerX,
  centerY,
  justifyContent,
  alignItems,
  style,
  flex,
  className,
  desktopSpan,
  tabletSpan,
  mobileSpan,
  onClick
}) => {
  return (
    <div
      className={`voyage-flex-layout ${vertical && 'vertical'} ${
        horizontal && 'horizontal'
      } ${centerX && 'center-x'} ${centerY && 'center-y'} ${
        desktopSpan && 'desktop-span-' + desktopSpan
      } ${tabletSpan && 'tablet-span-' + tabletSpan} ${
        mobileSpan && 'mobile-span-' + mobileSpan
      } ${className}
      `}
      style={{
        gap: gap,
        justifyContent: justifyContent,
        alignItems: alignItems,
        flex: flex,
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default Layout
