import css from "./general.module.css";

const LandingSection = ({
  dark,
  light,
  hero,
  children,
  outerStyle,
  innerStyle,
  background,
  videoStyle,
  videoOuterStyle,
}) => {
  // const heroVideos = {
  //   default:
  //     "https://s3.us-east-2.amazonaws.com/static.aidungeon.io/content-images/landing-page/aid-cinemagraph.mp4",
  //   unchained:
  //     "https://s3.us-east-2.amazonaws.com/static.aidungeon.io/content-images/landing-page/unchained-golem.mp4",
  //   phoenix:
  //     "https://s3.us-east-2.amazonaws.com/static.aidungeon.io/content-images/landing-page/phoenix-header.mp4",
  // };
  const heroBackgrounds = {
    default:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/409c2e8d-4e1a-4c4c-df27-a25e70efed00/foil",
    unchained:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/a2942b18-6828-4dad-a0a1-51cbd479d400/foil",
    phoenix:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/a24d70e2-4f06-44b8-5a10-c9d6fca24000/foil",
    renaissance:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/1f8e24f2-8691-469b-c998-368c4353f000/foil",
    pathfinder:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/0a883840-bb16-40b8-04b0-ec3420b7b100/foil",
    ember:
      "https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/5bfbe210-ce53-44ca-3b6f-74008db69600/foil"
  };
  return (
    <div
      className={`${css["ai-dungeon-landing-section"]} ${css[dark && "dark"]} ${
        css[light && "light"]
      } ${css[hero && "hero"]}`}
      style={outerStyle}
    >
      {hero && (
        <div style={videoOuterStyle}>
          {/* <video
            autoPlay
            muted
            loop
            className={css["aid-bg-video"]}
            style={videoStyle}
          >
            <source src={heroVideos[hero]} type="video/mp4" />
          </video> */}
          
          <img
            src={heroBackgrounds[hero]}
            className={css["aid-bg-video"]}
            alt="background"
            style={videoStyle}
          />
        </div>
      )}
      {hero === "default" && <div class={css["hero-gradient-overlay"]} />}
      {background && <div class={css["background-overlay"]} />}
      { hero === "pathfinder"&& <div class={css["pathfinder-overlay"]} />}
      { hero === "ember"&& <div class={css["ember-overlay"]} />}

      <div
        className={css["ai-dungeon-landing-section-inner"]}
        style={innerStyle}
      >
        {children}
      </div>
    </div>
  );
};

export default LandingSection;
