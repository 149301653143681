import React, { Suspense } from "react";

const SteamAppLand = React.lazy(() => import("./SteamAppLand"));

const Loading = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "var(--small)",
      }}
    >
      <div className="aid-spinner" />
      <h4>Loading...</h4>
    </div>
  );
};

const HomeLoader = () => {
  //everything below
  return (
    <>
        <Suspense fallback={<Loading />}>
          <SteamAppLand />
        </Suspense>
    
    </>
  );
};

export default HomeLoader;
