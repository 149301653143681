import React from "react";
import css from "./general.module.css";
import Layout from "../voyage-ui/building-blocks/Layout/Layout";
import NavBarLink from "./NavBarLink";

export default function NavBar() {
  const startAID = () => {
    window.location.href = "https://play.aidungeon.com";
  };

  return (
    <div
      className={css["aid-top-nav"]}
      style={{
        backgroundColor: `rgba(0,0,0,1})`,
        border: `1px solid rgba(60,60,60,2})`,
      }}
    >
      <Layout horizontal flex={1} centerX gap="var(--c0)">
        <img
          className={css["nav-mobile-hidden"]}
          src="https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/7660e689-0d3d-4a2c-25f3-39fd73b32900/public"
          alt=""
          style={{ height: "18px", marginRight: "var(--c3)" }}
        />
        <Layout
          centerX
          centerY
          flex={0.7}
          className={css["nav-mobile-visible"]}
        >
          <img
            src="https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/df8037c9-c48e-4d39-b72f-dbc7eea75200/public"
            alt=""
            style={{ height: 52, width: 52 }}
          />
        </Layout>
        <NavBarLink label="Home" iconName="home" to="/" />
        <NavBarLink label="Updates" iconName="updates" to="/updates" />
        <a
          href="https://play.aidungeon.com"
          style={{ flex: 1 }}
          className={css["nav-mobile-visible"]}
        >
          <NavBarLink label="Sign In" iconName="signup" to="link" />
        </a>
      </Layout>
      <button className={css["nav-mobile-hidden"]} onClick={() => startAID()}>
        Sign in
      </button>
    </div>
  );
}
